import React, { useEffect, useState } from "react";
import CustomWidget from "../../../components/CustomWidget";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import WidgetCustomizer from "../../../components/WidgetCustomizer";
import { storeInLocalStorage } from "../../../services/localStorage";
import { api } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderComponent from "../../../components/LoaderComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBotsByProjectId,
  setAllProjects,
  setProjectId,
} from "../../Auth/store/authSlice";
import CreditsModal from "../../../components/CreditsModal";

const WidgetSettings = (props) => {
  const dispatch = useDispatch();
  const { from, session } = props;
  const state = useSelector((state) => state?.auth);
  const [sessionId, setSessionId] = useState();

  const [widgetAppereance, setWidgetAppearance] = useState({
    profile_pic: null,
    initial_message: "Hi, how can I help you?",
    initial_suggested_prompt: ["Great! how are you?"],
    theme: "light",
    suggestedMsgBackgroundColor: "#F3F4F6",
    timeTextColor: "#6F7E94",
    ai_msg_background_color: "#000C60",
    visitor_msg_background_color: "#F2F2F4",
    ai_font_color: "white",
    font_color: "black",
    widget_header_color: null,
    widget_icon_color: null,
  });
  const [loading, setLoading] = useState(false);
  const [creditsModal, setCreditsModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const getWidgetSettings = async (projectId, botId) => {
    try {
      setLoading(true);
      const response = await api.get(
        `customization/?bot_id=${botId}&project_id=${projectId}`
      );
      setWidgetAppearance({ ...widgetAppereance, ...response?.data });
    } catch (error) {
      // console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    const getProjectsAndBots = async (endpoint) => {
      try {
        let response = await api.get(`${endpoint}/`);
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    };

    const apiEndpoints = ["projects", "bots"];

    const promises = await Promise.all(
      apiEndpoints?.map((endpoint) => getProjectsAndBots(endpoint))
    );
    return promises;
  };
  const getData = async () => {
    setLoading(true);
    let data = await fetchData();

    if (data[0] === undefined) {
      navigate(`/signup-steps`);
      setLoading(false);
    } else {
      let projectId = state?.project_id ? state.project_id : data[0][0]?.id;
      let botId = state?.bot_id ? state.bot_id : data[1][0]?.id;
      dispatch(setAllProjects(data[0]));
      dispatch(fetchBotsByProjectId({ projectId: projectId, botId: botId }));

      dispatch(setProjectId(projectId));
      // dispatch(setBotId(botId));

      await storeInLocalStorage("project_id", projectId);
      await storeInLocalStorage("bot_id", botId);
      await getWidgetSettings(projectId, botId);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state?.project_id || !state?.bot_id) {
      getData();
    } else {
      getWidgetSettings(state?.project_id, state?.bot_id);
    }
  }, [state?.project_id, state?.bot_id]);

  const handleCreditModalOpen = () => setCreditsModal(true);
  const handleCreditModalClose = () => setCreditsModal(false);

  return (
    <Box sx={{ flexGrow: 1 }} className="">
      {from === "conversations" ? (
        <div style={{ height: "500px" }}>
          <CustomWidget
            sessionId={session}
            widgetAppereance={widgetAppereance}
            setWidgetAppearance={setWidgetAppearance}
            playground="true"
            fromConv={true}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              cursor: "pointer",
              background: "#ffebeb",

              padding: "1.5rem",
              borderRadius: "0.5rem",
              boxShadow:
                "0px 0px .9310142993927002px 0px rgba(0, 0, 0, .17), 0px 0px 3.1270833015441895px 0px rgba(0, 0, 0, .08), 0px 7px 14px 0px rgba(0, 0, 0, .05)",
            }}
          >
            <p className="text-center font-medium">
              <span
                className=" cursor-pointer underline"
                onClick={handleCreditModalOpen}
              >
                Upgrade your membership
              </span>{" "}
              to customize design and train on your own data
            </p>
          </div>

          <Grid container spacing={4} className="relative pt-3 ">
            <Grid item xs={12} lg={6}>
              <WidgetCustomizer
                widgetAppereance={widgetAppereance}
                setWidgetAppearance={setWidgetAppearance}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomWidget
                setSessionId={setSessionId}
                widgetAppereance={widgetAppereance}
                setWidgetAppearance={setWidgetAppearance}
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* Update Membership Modal */}
      {creditsModal && (
        <CreditsModal
          open={creditsModal}
          handleClose={handleCreditModalClose}
        />
      )}
      <LoaderComponent loading={loading} />
    </Box>
  );
};

export default WidgetSettings;
