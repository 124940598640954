import React, { useEffect, useRef, useState } from "react";
import {
  createSlug,
  getDate,
  getRandomObjects,
} from "../../../../utils/helper";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { actionMsgs, suggestedMsgs } from "../../../../data/suggestedMsgs.js";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LoaderComponent from "../../../LoaderComponent/index.js";
import profile_icon from "../../../../assets/images/profile_icon.svg";
const MessagesRow = ({
  widgetAppereance,
  end,
  messages,
  botMsgs,
  setBotMsgs,
  scrollToBottom,
  setMessages,
  endTraining,
  chatEnded,
  conversationType,
  setEnd,
  appendString,
  loading,
}) => {
  const [open, setOpen] = React.useState(false);
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const [fileName, setFileName] = useState();
  const handleClickOpen = () => {
    setShowSuggestions(false);
    setOpen(true);
  };

  const handleClose = () => {
    setShowSuggestions(true);
    setOpen(false);
  };

  let singleString = botMsgs.join("");

  useEffect(() => {
    if (!end && singleString !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          text: singleString,
          timestamp: new Date(),
        },
      ]);
    } else {
      setBotMsgs([]);
    }
  }, [end]);

  const handleClickChipAction = async (e, chip) => {
    if (chip?.action === "finished") {
      handleClickOpen();
      return;
    }
    if (chip?.action === "more") {
      let splitString = chip?.answer?.split("");
      setEnd(true);
      for (let i = 0; i < splitString.length; i++) {
        const char = splitString[i];
        await new Promise((resolve) => setTimeout(resolve, 10));
        appendString(char);
        scrollToBottom();
      }
      setEnd(false);

      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   {
      //     id: messages.length + 1,
      //     text: chip?.answer,
      //     timestamp: new Date(),
      //   },
      // ]);

      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,

      {
        id: messages.length + 1,
        text: chip?.answer,
        timestamp: new Date(),
      },
    ]);
  };

  const handleSave = async () => {
    let file = createSlug(fileName);
    let fullFileName = file + ".txt";
    await endTraining(fullFileName);
    setOpen(false);
    setShowSuggestions(false);
  };

  const yourDivRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when messages or actionMsgs change
    scrollToBottom();
  }, [messages, actionMsgs, scrollToBottom]);

  return (
    <>
      <div className="grow">
        {messages?.map((msg, index) =>
          msg?.isVisitor ? (
            <>
              <div className={`flex flex-col ${"items-end"} `} key={index}>
                <div className="bot_msg items-end flex flex-col max-w-[70%] relative">
                  <div className="msg_time flex items-center justify-center ml-3 text-sm	mt-2">
                    <p style={{ color: widgetAppereance?.timeTextColor }}>
                      {getDate(msg?.timestamp)}
                    </p>
                  </div>
                  {msg?.voice_path ? (
                    <audio src={msg?.voice_path} controls></audio>
                  ) : (
                    <p
                      className="rounded  text-white p-3 text-sm  w-[100%] break-words	"
                      style={{
                        backgroundColor:
                          widgetAppereance.visitor_msg_background_color ??
                          "#F2F2F4",
                        color: widgetAppereance.font_color ?? "black",
                      }}
                    >
                      <span>{msg?.text}</span>
                    </p>
                  )}
                  <div
                    className="triangle"
                    style={{
                      backgroundColor:
                        widgetAppereance.visitor_msg_background_color ??
                        "#F2F2F4",
                    }}
                  ></div>
                </div>

                <br />
              </div>
              {scrollToBottom()}
            </>
          ) : (
            <>
              <div className={`flex flex-col ${"items-start"}`} key={index}>
                <div className="visitor_msg items-start flex flex-col max-w-[70%] relative">
                  <div className="msg_time flex items-center justify-center ml-3 text-sm	mb-2">
                    <p style={{ color: widgetAppereance?.timeTextColor }}>
                      {getDate(msg?.timestamp)}
                    </p>
                  </div>
                  <span
                    className="rounded  p-3 w-[100%] text-sm break-words	"
                    style={{
                      backgroundColor:
                        widgetAppereance.ai_msg_background_color ?? "#000C60",
                      color: widgetAppereance.ai_font_color ?? "white",
                    }}
                  >
                    {msg?.text}
                  </span>
                  <div
                    className="triangle-reverse"
                    style={{
                      backgroundColor:
                        widgetAppereance.ai_msg_background_color ?? "#000C60",
                    }}
                  ></div>
                </div>
                <img
                  className="mt-6 ml-3"
                  src={profile_icon}
                  alt="profile_icon"
                />
                <br />
              </div>
              {scrollToBottom()}
            </>
          )
        )}

        {end && botMsgs?.length !== 0 && (
          <div className={`flex flex-col ${"items-start"}`}>
            <div className="visitor_msg items-start flex flex-col max-w-[70%]">
              <span
                className="rounded  p-3 w-[100%] text-sm break-words	"
                style={{
                  backgroundColor: widgetAppereance.ai_msg_background_color,
                  // color: "white",
                  color: widgetAppereance.ai_font_color,
                }}
              >
                {botMsgs?.map((msg) => msg)}
                {scrollToBottom()}
              </span>
            </div>

            <br />
          </div>
        )}
      </div>
      {!end && showSuggestions && conversationType === "train" && (
        <>
          {/* <div className="flex justify-end items-end">
            {randomObjects && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="right"
                className="!mb-5"
                flexWrap="wrap"
                maxWidth="70%"
              >
                {randomObjects?.map((chip) => (
                  <Chip
                    className="!my-1"
                    label={chip.question}
                    key={chip.id}
                    onClick={(e) => handleClickChipAction(e, chip)}
                  />
                ))}
                {scrollToBottom()}
              </Stack>
            )}
          </div> */}

          {messages?.length > 1 && (
            <>
              <Stack direction="row" spacing={1} justifyContent="right">
                <Chip label="Do you want to add more?" />
                {actionMsgs?.map((chip) => (
                  <div key={chip.id}>
                    <Chip
                      label={chip.question}
                      icon={chip.icon}
                      onClick={(e) => handleClickChipAction(e, chip)}
                    />
                  </div>
                ))}
              </Stack>
              <div ref={yourDivRef} className="your-div">
                {/* Your content here */}
              </div>
            </>
          )}
        </>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle> Enter the file name for your model</DialogTitle>
        <DialogContent>
          <TextField
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="eg. output, train"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
        <LoaderComponent loading={loading} />
      </Dialog>
    </>
  );
};

export default MessagesRow;
