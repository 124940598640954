import React, { useState } from "react";
import { getFormValues } from "../../../utils/helper";
import { useNavigate, Navigate } from "react-router";
import { countries } from "../../../constants/countries";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { CHATBOT_CORE_API_URL } from "../../../constants/URLS";
import tick from "../../../assets/images/tick.png";
import google from "../../../assets/images/google.png";
import microsoft from "../../../assets/images/microsoft.png";
import apple from "../../../assets/images/apple.png";
import CustomHeading from "../../../components/CustomHeading";
import Navbar from "../../../Layout/Navbar";
import CustomButton from "../../../components/CustomButton";

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [phone_no, setPhoneNo] = useState("");
  const token = localStorage.getItem("access_token");
  const location = "/widget-settings";

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    const values = await getFormValues(event);
    let formValues = {
      ...values,
      picture: "string",
      phone_no: phone_no,
    };

    try {
      const response = await axios.post(
        `${CHATBOT_CORE_API_URL}users/`,
        formValues
      );
      enqueueSnackbar("Successfully registered!", { variant: "success" });
      navigate("/login");
    } catch (error) {
      console.log("Error", error?.response?.data?.detail);
      enqueueSnackbar(`${error?.response?.data?.detail}`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (token) return <Navigate to={location}></Navigate>;

  return (
    <>
      <Navbar showHeaderRight={false} />
      <div className=" flex h-[100%] signup_cont  justify-center items-center p-5 ">
        <div className="w-[80%] flex ">
          <div className="w-[100%] justify-center items-start md:flex flex-col hidden   ">
            <CustomHeading text="Join millions worldwide who automate their work using Botnest." />
            <br />
            <ul className="list-none	">
              <li className="flex justify-start items-center mt-2">
                <img src={tick} alt="social_icons" className="h-[14px]" />
                <p className="ms-2">
                  3-day free trial (no credit card, no charges)
                </p>
              </li>

              <li className="flex justify-start items-center mt-2">
                <img src={tick} alt="social_icons" className="h-[14px]" />
                <p className="ms-2">Free Object Cache Pro & SSL Certificate</p>
              </li>

              <li className="flex justify-start items-center mt-2">
                <img src={tick} alt="social_icons" className="h-[14px]" />
                <p className="ms-2">24/7/365 real-time expert support</p>
              </li>

              <li className="flex justify-start items-center mt-2">
                <img src={tick} alt="social_icons" className="h-[14px]" />
                <p className="ms-2">Unlimited websites</p>
              </li>
            </ul>
          </div>
          <div className="w-[100%] justify-center items-center flex flex-col ">
            <div className="shadow-lg w-[100%] flex  py-4 flex-col justify-center px-6  border bg-white  border-slate-300 rounded  lg:px-8">
              {/* Social Icons */}
              <ul className="list-none flex justify-center items-center">
                <li>
                  <img
                    src={google}
                    alt="social_icons"
                    className="w-[30px] ms-8"
                  />
                </li>
                <li>
                  <img
                    src={microsoft}
                    alt="social_icons"
                    className="w-[30px] ms-8"
                  />
                </li>
                <li>
                  <img
                    src={apple}
                    alt="social_icons"
                    className="w-[30px] ms-8"
                  />
                </li>
              </ul>

              <Divider className="!my-4">or</Divider>
              <div className="">
                <form
                  className="space-y-4"
                  onSubmit={handleSubmitForm}
                  encType="multipart/form-data"
                >
                  <div className="gap-y-4 grid  gap-x-6 lg:grid-cols-2 md:grid-cols-1">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Full Name
                      </label>
                      <div className="mt-2">
                        <input
                          placeholder="Enter your name"
                          id="first_name"
                          name="first_name"
                          type="text"
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          placeholder="name@info.com"
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    {/* <div>
                      <label
                        htmlFor="country_code"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Select country
                      </label>
                      <div className="mt-2">
                        <select
                          placeholder="Select country"
                          id="country_code"
                          name="country_code"
                          className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue={""}
                        >
                          {countries?.map((country) => (
                            <option key={country?.code} value={country?.code}>
                              {country?.name}
                            </option>
                          ))}
                          <option>Light</option>
                          <option>Dark</option>
                        </select>
                      </div>
                    </div> */}
                  </div>

                  <div>
                    <label
                      htmlFor="phone_no"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        label="Enter your Phone number"
                        country={"pk"}
                        id="phone_no"
                        name="phone_no"
                        type="number"
                        value={phone_no}
                        onChange={(e) => setPhoneNo(e)}
                        inputProps={{
                          required: true,
                        }}
                        inputClass="!block !w-full !rounded-md !border-0 !py-1.5 !text-gray-900 !shadow-sm !ring-1 !ring-inset !ring-gray-300 !placeholder:text-gray-400 !focus:ring-2 !focus:ring-inset !focus:ring-indigo-600 !sm:text-sm !sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        placeholder="Enter your password"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  {/* <div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign up
                  </button>
                </div> */}

                  <div className="flex justify-center item-center">
                    <CustomButton title=" Sign up" type="submit" />
                  </div>
                </form>
                <p className="mt-5 text-center text-sm text-gray-500">
                  By signing up, you agree to Bot nest{" "}
                  <a href="">terms of service</a> and{" "}
                  <a href="#">privacy policy.</a> 
                </p>

                <p className=" text-center text-sm text-gray-500">
                  Already a member?{" "}
                  <Link
                    to="/login"
                    className="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Signup;
